import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'

let timer = null;
const PAGESIZE = 30;
const Index = ({ dispatch, clearValue, label, onChange, placeholder, refCallback, isInternational, defaultValue, className, disablePoint }) => {
  const input = refCallback || useRef()
  const [dataList, setDataList] = useState([]);
  const [dataStore, setDataStore] = useState([]);
  const [value, setValue] = useState("")
  const [isFocus, setIsfocus] = useState(false);

  const renderList = (_list, type) => {
    const newList = _list?.filter(x => x.airportCode !== disablePoint) || [];
    setDataList(newList);
    if (type === 'saveStore') setDataStore(newList);

  }

  useEffect(() => {
    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [])

  useEffect(
    () => {
      setValue(defaultValue)
    }, [defaultValue]
  )

  useEffect(() => {
    renderList(dataList)
  }, [disablePoint])

  // Khi mới click thì view các sân bay Việt Nam
  const fetchData = () => {
    const filter = { isInternational: 0, status: 1 }
    // if (Number(isInternational) === 1) {
    //    filter.isInternational = 1;
    // }
    // const filter = { isInternational }
    // if (isInternational === '1') {
    //   delete filter.isInternational
    // }
    dispatch({
      // type: 'webs/fetchAirports',
      type: 'webs/fetchSearchAirports',
      payload: {
        filter: JSON.stringify(filter),
        sort: JSON.stringify(['airportName', 'asc']),
        range: JSON.stringify([0, PAGESIZE - 1]),
        attributes: `id,airportName,airportCode`,

      },
      callback: res => {
        // console.log('res: ', res);

        if (res.success) {
          // eslint-disable-next-line no-underscore-dangle
          const _list = res.result && res.result.list || []
          renderList(_list, 'saveStore')
        }
      },
    });
  }

  useEffect(() => {
    fetchData()
  }, [])

  // Tìm kiếm tất cả các sân bay
  const onChangeText = (e) => {
    // eslint-disable-next-line no-underscore-dangle
    const _value = e.target.value;
    setValue(_value)
    if (timer) {
      clearTimeout(timer)
      timer = null
    }
    if (!_value) {
      setDataList(dataStore);
      return;
    }
    timer = setTimeout(() => {
      const params = {
        // isInternational : 0,
        airportsFilter: _value && _value.trim(),
        status: 1
      }
      if (!_value) {
        delete params.airportsFilter
      }
      // if (isInternational === '1') {
      //   delete params.isInternational 
      // }
      dispatch({
        type: 'webs/fetchSearchAirports',
        payload: {
          filter: JSON.stringify(params),
          sort: JSON.stringify(['airportName', 'asc']),
          range: JSON.stringify([0, PAGESIZE - 1]),
          attributes: `id,airportName,airportCode`,

        },
        callback: res => {
          if (res.success) {
            // eslint-disable-next-line no-underscore-dangle
            const _list = res.result && res.result.list || []
            renderList(_list)
          }
        },
      });
    }, 350)
  }
  // useEffect(() => {
  //   if (onFocus) {
  //     input.current.focus()
  //   }
  // }, [onFocus])

  const handleClear = () => {
    setValue("")
    if (clearValue) {
      clearValue();
      fetchData();
    }
  }
  // console.log('dataList: ', dataList);

  return (
    <div className={`${className} banner-clear banner-clear-maybay` || "banner-main__input banner-clear banner-clear-maybay"}>
      <span className="label">{label}</span>
      <input
        ref={input}
        type="text"
        placeholder={placeholder}
        value={value}
        onFocus={() => setIsfocus(true)}
        onChange={onChangeText}
        onBlur={() => {
          setTimeout(() => { setIsfocus(false) }, 200)
        }}
      />
      {value && <i className="far fa-times-circle" onClick={handleClear} />}
      {dataList && dataList.length > 0 &&
        <div className="select-menu " style={{ display: isFocus ? 'block' : 'none' }}>
          <h5 className="select-menu__title">
            Địa điểm
          </h5>
          <ul className="select-menu__list maybay ">
            {dataList.map(item => {
              const countrieName = item.countries?.countrieName || '';
              const provinceName = item.provinces?.provinceName || '';
              return (
                (
                  <li
                    key={item.id}
                    onClick={() => {
                      setValue(`${item.airportName}, ${countrieName}`)
                      if (onChange) {
                        onChange({
                          codeAirport: item.airportCode,
                          provinceName,
                          countrieName,
                        })
                      }
                    }}
                  >
                    <b>{item.airportCode}</b>
                    <div style={{ lineHeight: 1.2 }}>
                      <span>{provinceName}, {countrieName}</span>
                      <span> - {item.airportName}</span>
                    </div>
                  </li>

                )
              )
            })}
          </ul>
        </div>}
    </div>
  )
}

export default connect()(Index) 