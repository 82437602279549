import React, { useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import SelectProvince from '@/componentWebs/Common/SelectProvince';
import { stringify } from 'qs';
import SelectCountries from '@/componentWebs/Common/SelectCountries';

moment.locale('vi');
const { RangePicker } = DatePicker;
const Index = ({ defaultValue, International }) => {
  // const [state, setState] = useState({
  //   // travelTourPackageDepart: [moment(), moment()],
  //   diaDiem: ,
  // });

  const [diaDiem, setdiaDiem] = useState('');
  const [diaDiemText, setdiaDiemText] = useState('');
  const [key, setKey] = useState(Math.random());

  // const [travelTourPackageDepart, setTravelTourPackageDepart] = useState([mo]);
  useEffect(() => {
    if (defaultValue && defaultValue.diaDiemText) {
      setdiaDiemText(defaultValue.diaDiemText);
    }
    if (defaultValue && defaultValue.diaDiem && defaultValue.diaDiem.length > 0) {
      setdiaDiem([defaultValue.diaDiem[0]]);
    }

    setKey(Math.random());
  }, []);

  const [isInternational, setIsInternational] = useState('0');

  useEffect(
    () => {
      setIsInternational(International);
    },
    [International]
  );

  const onSubmit = e => {
    e.preventDefault();
    // const { travelTourPackageDepart, diaDiem } = state;

    const params = {
      diaDiem,
      // isInternational,
      diaDiemText,
      // travelTourPackageDepart: [moment(travelTourPackageDepart[0], 'DD/MM/YYYY').format('YYYY-MM-DD'), moment(travelTourPackageDepart[1], 'DD/MM/YYYY').format('YYYY-MM-DD')],
    };

    window.location.href = `/searchVisa?${stringify(params)}`;
  };

  const clearValue = () => {
    setdiaDiem("")
    setdiaDiemText("")
  }

  return (
    <form className="banner-main__box-form flex form-hotel form-visa-mobile">
      <div className="banner-main__box-formItem searchKhachsanMobile" style={{ borderRight: 'none' }}>
        <div className="banner-main__input banner-main__select select-countries">
          <SelectCountries
            label="Quốc gia"
            placeholder="Nhập nước bạn muốn đến"
            key="from"
            defaultValue={diaDiemText}
            isInternational={isInternational}
            onChange={rec => {
              setdiaDiem([rec.id]);
              setdiaDiemText(`${rec.countrieName}`);
            }}
            clearValue={clearValue}

          />
        </div>

        <button className="btn btn--filter flex-a-c hidden-m " onClick={onSubmit} type="button">
          <span>Tìm kiếm</span>
          <img src="/static/vietnamTickets/images/Icon feather-search.svg" alt="search" className='lazyload' />
        </button>
      </div>

      <button className="btn btn--filter flex-a-c hidden-pc hidden-t" onClick={onSubmit} type="button">
        <span>Tìm kiếm</span>
        <img src="/static/vietnamTickets/images/Icon feather-search.svg" alt="search" className='lazyload' />
      </button>
    </form>
  );
};

export default Index;
