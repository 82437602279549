import React, { useState, useRef, useEffect } from 'react';
import { DatePicker, Popover } from 'antd';
import moment from 'moment';
import SelectPointsFlignhts from '@/componentWebs/vietnamTickets/SelectPointsFlignhts';
import { stringify } from 'qs';

moment.locale('vi');

const Index = ({ defaultValue, International }) => {
  const inputTo = useRef();
  const [state, setState] = useState({
    StartPoint: '',
    EndPoint: '',
    startName: '',
    startCountry: '',
    endName: '',
    endCountry: '',
    DepartDate: moment(),
    DepartDate2: moment(),
    Adt: 1,
    Chd: 0,
    Inf: 0,
  });

  const [isRoundTrip, setIsRoundTrip] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isInternational, setIsInternational] = useState(isInternational || '0');

  useEffect(() => {
    if (defaultValue) {
      setState({
        ...state,
        ...defaultValue,
      });
      if (defaultValue.DepartDate2) {
        setIsRoundTrip(true);
      }
    }
  }, []);

  useEffect(
    () => {
      setIsInternational(International);
    },
    [International]
  );

  const onSubmit = e => {
    e.preventDefault();
    const {
      StartPoint,
      EndPoint,
      startName,
      startCountry,
      endName,
      endCountry,
      DepartDate,
      DepartDate2,
      Adt,
      Chd,
      Inf,
    } = state;
    if (!StartPoint || !EndPoint || !DepartDate) {
      return;
    }
   
    const params = {
      Adt,
      Chd,
      Inf,
      ViewMode: '',
      StartPoint,
      EndPoint,
      DepartDate: moment(DepartDate).format('YYYY-MM-DD'),
      DepartDate2: moment(DepartDate2).format('YYYY-MM-DD'),
      startName,
      startCountry,
      endName,
      endCountry,
      isInternational,
    };
    if (!isRoundTrip) {
      delete params.DepartDate2;
    }
    window.location.href = `/searchTicket?${stringify(params)}`;
    // router.push(`/User/SearchTicket`, `/searchTicket?${stringify(params)}`)
  };

  const onChangeQty = (field, count) => {
    setState({
      ...state,
      [field]: count,
    });
  };

  const clearValue = type => {
    if (type === 'from') {
      setState({
        ...state,
        StartPoint: '',
        startName: '',
        startCountry: '',
      });
    } else {
      setState({
        ...state,
        EndPoint: '',
        endName: '',
        endCountry: '',
      });
    }
  };

  const { Adt, Chd, Inf, EndPoint, StartPoint } = state;

  return (
    <>
      <form className="banner-main__box-form flex banner-form-maybay-custom">
        <div className="banner-main__box-formItem">
          <SelectPointsFlignhts
            label="Từ"
            placeholder="Điểm khởi hành"
            className="banner-main__input search-left"
            key="from"
            defaultValue={state.startName && `${state.startName}, ${state.startCountry}`}
            // isInternational={isInternational}
            clearValue={() => clearValue('from')}
            disablePoint={EndPoint}
            onChange={rec => {
              inputTo.current.focus();
              setState({
                ...state,
                StartPoint: rec.codeAirport,
                startName: rec.provinceName,
                startCountry: rec.countrieName,
              });
            }}
          />

          <img
            src="/static/vietnamTickets/images/Icon awesome-exchange-alt.svg"
            alt="icon-places"
            className="lazyload"
          />

         
            <SelectPointsFlignhts
              label="Đến"
              placeholder="Điểm đến"
              className="banner-main__input search-rigth"
              // isInternational={isInternational}
              key="to"
              refCallback={inputTo}
              clearValue={() => clearValue('to')}
              disablePoint={StartPoint}
              defaultValue={state.endName && `${state.endName}, ${state.endCountry}`}
              onChange={rec => {
                setState({
                  ...state,
                  EndPoint: rec.codeAirport,
                  endName: rec.provinceName,
                  endCountry: rec.countrieName,
                });
              }}
            />
        </div>
        <div className="banner-main__box-formItem" style={{ alignItems: 'flex-start' }}>
          <div className="banner-main__input banner-main__select">
            <span className="label">Ngày đi</span>

            <DatePicker
              placeholder="Chọn ngày"
              disabledDate={val => val < moment().subtract(1, 'days')}
              inputReadOnly
              format="DD/MM/YYYY"
              className="dateFiler"
              allowClear={false}
              onChange={rec => {
                if (moment(state.DepartDate2) - rec < 0) {
                  setState({ ...state, DepartDate: rec, DepartDate2: rec });
                } else {
                  setState({ ...state, DepartDate: rec });
                }
              }}
              value={moment(state.DepartDate)}
            />
          </div>
          <div className="banner-main__input search-rigth">
            <span className="label">
              <span>Ngày về</span>
              <input
                type="checkbox"
                className="switch_toogle"
                checked={isRoundTrip}
                onChange={() => setIsRoundTrip(!isRoundTrip)}
              />
              {/* <i
              className={`fas fa-toggle-${isRoundTrip ? 'on' : 'off'}`}
              style={{ color: '#eb0281', cursor: 'pointer' }}
              onClick={() => setIsRoundTrip(!isRoundTrip)}
            /> */}
            </span>

            {isRoundTrip ? (
              <DatePicker
                placeholder="Chọn ngày"
                disabledDate={val => val < moment(state.DepartDate)}
                format="DD/MM/YYYY"
                className="dateFiler"
                inputReadOnly
                allowClear={false}
                onChange={rec => {
                  setState({ ...state, DepartDate2: rec });
                }}
                value={moment(state.DepartDate2)}
              />
            ) : (
              <div className="div-height-maybay" />
            )}
          </div>
        </div>
        <div
          className="banner-main__box-formItem banner-main__box-formItem-search flex-b-c"
          style={{ alignItems: 'flex-start' }}
        >
          <div className="banner-main__input mobileSearch" style={{ position: 'relative' }}>
            <span className="label">Số khách</span>

            <div className="dropdown_title" type="button" onClick={() => setShowDropdown(true)}>
              {`${Adt} người lớn, ${Chd} trẻ em, ${Inf} em bé`}
            </div>
            {showDropdown && (
              <>
                <div
                  onClick={() => setShowDropdown(false)}
                  style={{
                    position: 'fixed',
                    width: '100vw',
                    height: '100vh',
                    top: 0,
                    left: 0,
                    backgroundColor: 'transparent',
                  }}
                />
                <ul className="dropdown-list">
                  <li className="dropdown-item">
                    <span>Người lớn (từ 12 tuổi)</span>
                    <div className="dropdown-block-btn">
                      <span
                        className="dropdown_btn"
                        onClick={() => {
                          const newCount = Number(Adt) > 1 ? Number(Adt) - 1 : Number(Adt);
                          onChangeQty('Adt', newCount);
                        }}
                      >
                        -
                      </span>
                      <span className="dropdown_count">{Adt}</span>
                      <span
                        className="dropdown_btn"
                        onClick={() => {
                          const newCount =
                            Number(Adt) < 7 && Number(Adt) + Number(Chd) + Number(Inf) < 11
                              ? Number(Adt) + 1
                              : Number(Adt);
                          onChangeQty('Adt', newCount);
                        }}
                      >
                        +
                      </span>
                    </div>
                  </li>
                  <li className="dropdown-item">
                    <span>Trẻ em (từ 2 - 11 tuổi)</span>
                    <div className="dropdown-block-btn">
                      <span
                        className="dropdown_btn"
                        onClick={() => {
                          const newCount = Number(Chd) > 0 ? Number(Chd) - 1 : Number(Chd);
                          onChangeQty('Chd', newCount);
                        }}
                      >
                        -
                      </span>
                      <span className="dropdown_count">{Chd}</span>
                      <span
                        className="dropdown_btn"
                        onClick={() => {
                          const newCount =
                            Number(Chd) < 6 && Number(Adt) + Number(Chd) + Number(Inf) < 11
                              ? Number(Chd) + 1
                              : Number(Chd);
                          onChangeQty('Chd', newCount);
                        }}
                      >
                        +
                      </span>
                    </div>
                  </li>
                  <li className="dropdown-item">
                    <span>Em bé (dưới 2 tuổi)</span>
                    <div className="dropdown-block-btn">
                      <span
                        className="dropdown_btn"
                        onClick={() => {
                          const newCount = Number(Inf) > 0 ? Number(Inf) - 1 : Number(Inf);
                          onChangeQty('Inf', newCount);
                        }}
                      >
                        -
                      </span>
                      <span className="dropdown_count">{Inf}</span>
                      <span
                        className="dropdown_btn"
                        onClick={() => {
                          const newCount =
                            Number(Inf) < 4 && Number(Adt) + Number(Chd) + Number(Inf) < 11
                              ? Number(Inf) + 1
                              : Number(Inf);
                          onChangeQty('Inf', newCount);
                        }}
                      >
                        +
                      </span>
                    </div>
                  </li>
                </ul>
              </>
            )}
          </div>
          <button className="btn btn--filter flex-a-c hidden-m-t" onClick={onSubmit} type="button">
            <span>Tìm kiếm</span>
            <img
              src="/static/vietnamTickets/images/Icon feather-search.svg"
              alt="search"
              className="lazyload"
            />
          </button>
        </div>

        <button className="btn btn--filter flex-a-c hidden-pc" onClick={onSubmit} type="button">
          <span>Tìm kiếm</span>
          <img
            src="/static/vietnamTickets/images/Icon feather-search.svg"
            alt="search"
            className="lazyload"
          />
        </button>
      </form>
      {/* <button
          className="btn btn--filter flex-a-c hidden-m-t"
          style={{ display: 'block' }}
          onClick={onSubmit}
          type="button"
        >
          <span>Tìm kiếm</span>
          <img src="/static/vietnamTickets/images/Icon feather-search.svg" alt="search" />
        </button> */}
    </>
  );
};

export default Index;
