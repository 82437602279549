import React, { useState, useEffect } from 'react';
import moment from 'moment';
import MayBay from '@/componentWebs/ComponentFilterBox/MayBay';
import Tour from '@/componentWebs/ComponentFilterBox/Tour';
import Combo from '@/componentWebs/ComponentFilterBox/Combo';
import Hotel from '@/componentWebs/ComponentFilterBox/Hotel';
import Visa from '@/componentWebs/ComponentFilterBox/Visa';

moment.locale('vi');

const Index = ({ defaultValue, typeTab, defaultInternational, getIsInternational, isDetail }) => {
  const [activeTab, setActiveTab] = useState('');
  const [isInternational, setIsInternational] = useState(defaultInternational || '0');

  useEffect(() => {
    if (typeTab) {
      setActiveTab(typeTab === 'all' ? 'maybay' : typeTab);
    }

    // if (defaultValue) {

    // }
  }, []);

  useEffect(
    () => {
      if (defaultInternational) {
        setIsInternational(defaultInternational);
      }

      if (defaultValue && defaultValue.isInternational) {
        setIsInternational(defaultValue.isInternational)
      }
    },
    [defaultInternational]
  );

  const handleInternational = id => {
    setIsInternational(id);
    if (getIsInternational) getIsInternational(id);
  };

  // console.log('defaultInternational: ', isInternational);

  return (

    <div className={typeTab === "all" ? `banner-main ${isDetail ? "hidden-m-t" : ""}` : `banner-main banner-main-detail ${isDetail ? "hidden-m-t" : ""}`}>
      <div className="banner-main__box">
        {!isDetail && (
          <div className="banner-main__box-nav flex-b-c">
            <ul className="banner-main__box-list flex-a-c" style={{ marginBottom: 0 }}>
              {(typeTab === 'maybay' || typeTab === 'all') && (
                <li>
                  <a
                    className={activeTab === 'maybay' ? 'active' : ''}
                    onClick={() => setActiveTab('maybay')}
                  >
                    <img className={typeTab === 'all' ? "lazyload" : "hidden-m-t lazyload"} src="/static/vietnamTickets/images/Path 3755.svg" alt="plane" />
                    <span>Vé máy bay</span>
                  </a>
                </li>
              )}

              {(typeTab === 'tour' || typeTab === 'all') && (
                <li>
                  <a
                    className={activeTab === 'tour' ? 'active' : ''}
                    onClick={() => setActiveTab('tour')}
                  >
                    <img className={typeTab === 'all' ? "lazyload" : "hidden-m-t lazyload"} src="/static/vietnamTickets/images/004-flag.svg" alt="tour" />
                    <span>{typeTab === "tour" ? "Đặt tour ngay để tiết kiệm đến 15% từ MyBooking!" : "Tour"}</span>
                  </a>
                </li>
              )}
              {(typeTab === 'combo' || typeTab === 'all') && (
                <li>
                  <a
                    className={activeTab === 'combo' ? 'active' : ''}
                    onClick={() => setActiveTab('combo')}
                  >
                    <img className={typeTab === 'all' ? "lazyload" : "hidden-m-t lazyload"} src="/static/vietnamTickets/images/earth1.svg" alt="Combo" />
                    <span>{typeTab === "combo" ? "Đặt combo ngay để tiết kiệm đến 15% từ MyBooking!" : "Combo"}</span>
                  </a>
                </li>
              )}
              {(typeTab === 'hotel' || typeTab === 'all') && (
                <li>
                  <a
                    className={activeTab === 'hotel' ? 'active' : ''}
                    onClick={() => setActiveTab('hotel')}
                  >
                    <img className={typeTab === 'all' ? "lazyload" : "hidden-m-t lazyload"} src="/static/vietnamTickets/images/003-hotel.svg" alt="Hotel" />
                    <span>{typeTab === "hotel" ? "Đặt phòng khách sạn liền tay, nhận ngay ưu đãi" : "Hotel"}</span>
                  </a>
                </li>
              )}
              {(typeTab === 'visa' || typeTab === 'all') && (
                <li>
                  <a
                    className={activeTab === 'visa' ? 'active' : ''}
                    onClick={() => setActiveTab('visa')}
                  >
                    <img className={typeTab === 'all' ? "lazyload" : "hidden-m-t lazyload"} src="/static/vietnamTickets/images/005-passport.svg" alt="Visa" />
                    <span>{typeTab === "visa" ? "Bạn muốn làm visa đi nước nào ?" : "Visa"}</span>
                  </a>
                </li>
              )}
            </ul>

            {activeTab !== 'visa' && activeTab !== 'maybay' && (
              <div className="banner-main__nav-text flex-a-c ">
                <div
                  className="banner-main__nav-text flex-a-c"
                  onClick={() => handleInternational('0')}
                >
                  <div className="checkbox_wrapper flex-center">
                    <span
                      className="checkbox_inner"
                      style={{ display: isInternational === '0' ? 'block' : 'none' }}
                    />
                  </div>
                  <span>Tìm nội địa</span>
                </div>
                <div
                  className="banner-main__nav-text flex-a-c"
                  style={{ marginLeft: 20 }}
                  onClick={() => handleInternational('1')}
                >
                  <div className="checkbox_wrapper flex-center">
                    <span
                      className="checkbox_inner"
                      style={{ display: isInternational === '1' ? 'block' : 'none' }}
                    />
                  </div>
                  <span>Tìm quốc tế</span>
                </div>
              </div>
            )}
          </div>
        )}

        <div className="banner-main__box-filter">
          {activeTab === 'maybay' && (
            <MayBay defaultValue={defaultValue} International={isInternational} />
          )}

          {activeTab === 'tour' && <Tour defaultValue={defaultValue} International={isInternational} />}

          {activeTab === 'combo' && <Combo defaultValue={defaultValue} International={isInternational} />}
          {activeTab === 'hotel' && (
            <Hotel defaultValue={defaultValue} International={isInternational} />
          )}
          {activeTab === 'visa' && (
            <Visa defaultValue={defaultValue} International={isInternational} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Index;
