import React, { Component } from 'react';
// import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Select, Spin } from 'antd';
import { /* defineMessages, */ injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fnKhongDau } from '@/utils/utils';
import _ from 'lodash';
import { stringify } from 'qs';

let timer = null

@connect(({ countries }) => ({
  countries,
}))
// @Form.create()
@injectIntl
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || '',
      loading: false,
      dataArr: [],
      icon: null,
      numOfScroll: 2,
      searchValue: '',
      checkState: true,
      totalItems: 0,
      text: props.text || '',
      dataStore: []
    };
  }

  componentDidMount() {
    const { filterField } = this.props
    const { value } = this.state;
      this.fetch(1, undefined, value, filterField, false, false, false)
  }

  componentWillReceiveProps(nextProps) {
    const { value } = this.state;
    // const { filterField } = this.props;
    if (nextProps.value && nextProps.value !== value) {
      this.setState({
        value: nextProps.value,
        // text: nextProps.text
      })
    };
    // if (nextProps.filterField && (nextProps.filterField !== filterField)) {
      // this.fetch(1, undefined, value, nextProps.filterField, true, false, false)
    // }
  }

  addItem = (value) => {
    const { dataArr, text, dataStore } = this.state;
    if (
      value !== undefined && text !== undefined && value !== '' && text !== '' && dataArr.findIndex(o => o.value === value) < 0
    ) {
      this.setState({
        dataArr: [{ text: `${text}`, value }, ...dataArr],
        dataStore: [{ text: `${text}`, value }, ...dataStore],
      });
    }
  }

  fetch = (current, flag, value, filterField, ChangeFilter, checkAddItem, checkDataAll) => {
    const pagesize = 100;
    const { dispatch } = this.props;
    const { dataArr, searchValue, dataStore } = this.state;
    const tfilter = {
      countrieName: searchValue,
      status: 1,
    };
    if (!searchValue || searchValue && !searchValue.trim()) {
      delete tfilter.countrieName;
    }
    const params = {
      filter: JSON.stringify(tfilter),
      range: JSON.stringify([pagesize * (current - 1), current * pagesize - 1]),
      sort: JSON.stringify(['countrieName', 'ASC']),
      attributes: 'id,countrieName'
    };
    dispatch({
      type: 'webs/fetchLazyLoading',
      payload: stringify(params),
      select: current !== 1,
      callback: result => {
        if (result && result.success === true) {
          
          const data =
            result &&
            result.result &&
            result.result.list.map(user => ({
              text: user.countrieName,
              value: user.id,
            }));
          this.setState({
            totalItems: result && result.result.pagination && result.result.pagination.total,
          });
          if (dataArr.length === 0 || flag) {
            // console.log('value', value)
            // if (data.findIndex(o => o.value === value.value) < 0) {
            //   data.unshift({ text: `${value.text}`, value: value.value })
            // }
            if (checkAddItem) {
              // console.log('aaaa: ', this.state.searchValue)
              if (searchValue) {
                this.setState({
                  dataArr: [..._.uniqBy([...dataArr, ...data], 'value')],
                  dataStore: [..._.uniqBy([...dataStore, ...data], 'value')],
                })
              } else {
                this.setState({
                  dataArr: [..._.uniqBy([...dataArr, ...data], 'value')],
                  dataStore: [..._.uniqBy([...dataStore, ...data], 'value')],
                }, () => { this.addItem(value) });
              }
            } else {
              this.setState({
                dataArr: [..._.uniqBy([...dataArr, ...data], 'value')],
                dataStore: [..._.uniqBy([...dataStore, ...data], 'value')],
              }, () => { this.addItem(value) });
            }
          } else if (ChangeFilter) {
            this.setState({
              dataArr: data,
              dataStore: data,
              text: '',
              value: '',
              numOfScroll: 2,
            })
          } else if (checkDataAll) {
            this.setState({
              dataArr: [..._.uniqBy([...dataStore, ...data], 'value')],
              dataStore: [..._.uniqBy([...dataStore, ...data], 'value')],
              checkState: true,
              numOfScroll: 2,
            })
          } else {
            // console.log('abc')
            this.setState(
              {
                dataArr: [..._.uniqBy([...dataArr, ...data], 'value')],
                dataStore: [..._.uniqBy([...dataStore, ...data], 'value')],
              },
              () => {
                this.setState({ checkState: true });
                // this.addItem(value)
              }
            );
          }
        }
        // this.setState({ checkrender: true })
      },
    });
  };

  handleScroll = e => {
    e.preventDefault();
    const { checkState, totalItems, value } = this.state;
    const { filterField } = this.props;
    const pagination = totalItems;
    // console.log('totalItems', totalItems)

    const { numOfScroll } = this.state;
    const isEndOfList = e.target.scrollTop + e.target.clientHeight;
    // log('scroll', checkState && isEndOfList >= 4 * 4 * e.target.scrollHeight / 5 / 5 && 20 * (numOfScroll) < pagination)

    if (
      checkState &&
      isEndOfList >= 4 * e.target.scrollHeight / 5 &&
      (20 * (numOfScroll - 1)) < pagination
    ) {
      // log('scroll1')
      this.setState({ numOfScroll: numOfScroll + 1, checkState: false }, () =>
        this.fetch(numOfScroll, undefined, value, filterField, false, false, false)
      );
    }
  };
  // renderData = data =>
  //   // log("renderTreeNodes data: ", data)
  //   (data || []).map(item => (
  //     <Select.Option value={`${item.value}`} key={item.value}>
  //       {item.text.trim()}
  //     </Select.Option>
  //   ));

  onChange = (value) => {
    const { dataArr } = this.state
    const { filterField } = this.props;
    if (value) {
      this.setState({
        value,
        text: dataArr.find(x => x.value === value) && dataArr.find(x => x.value === value).text
      });
    } else {
      // console.log('open1')
      this.setState({
        value,
        text: dataArr.find(x => x.value === value) && dataArr.find(x => x.value === value).text
      }, this.fetch(1, undefined, value, filterField, false, false, true));
    }
    const { onChange } = this.props;
  
    if (onChange)
      onChange(value, dataArr.find(x => x.value === value) && dataArr.find(x => x.value === value).text);
  }

  onfocus = () => {
    this.setState({
      icon: <i className="fa fa-search" />,
    });
  };

  onblur = () => {
    this.setState({
      icon: null,
    });
  };

  search = string => {
    const { value, dataStore } = this.state
    const { filterField } = this.props;
    const dataTemp = dataStore.filter(item => fnKhongDau(item.text).indexOf(fnKhongDau(string)) !== -1);
    if (string) {
      this.setState({
        dataArr: dataTemp,
        searchValue: string,
        numOfScroll: 2,
      });
    } else {
      this.setState({
        dataArr: dataStore,
        searchValue: '',
        numOfScroll: 2,
      })
    }
    clearTimeout(timer);
    timer = setTimeout(this.fetch.bind(this, 1, string, value, filterField, false, true, false), 500);
  };

  handleMouseLeave = () => {
    const { searchValue } = this.state;
    const { filter } = this.props
    if (searchValue && searchValue !== '' && filter) {
      this.setState({
        searchValue: '',
        // numOfScroll: 2,
      })
    }
    // else if (text && text.trim() && haveValue <= 0) {
    //   this.setState({ dataArr: [{ value: value, text: text }, ...dataArr] });
    // } else this.setState({ dataArr: [...dataArr] });
  };

  render() {
    const { key, placeholder, disabled, filter, size,style } = this.props;
    const { loading, value, dataArr, icon } = this.state;
    // console.log('state: ', this.state)
    // console.log('props: ', this.props)
    return (
      <React.Fragment>
        <Select
          key={key}
          onFocus={this.onfocus}
          onBlur={this.onblur}
          suffixIcon={icon}
          showArrow
          showSearch
          value={value || undefined}
          notFoundContent={loading ? <Spin size="small" /> : null}
          onChange={this.onChange}
          onSearch={this.search}
          filterOption={false}
          disabled={disabled}
          mode="multiple"
          // onKeyPress={e => this.handleKeyPress(e, record.key)}
          placeholder={placeholder}
          size={size}
          allowClear={!!filter}
          style={ {width: "100%"}}
          loading={loading}
          onPopupScroll={this.handleScroll}
          onDropdownVisibleChange={() => this.handleMouseLeave()}
        >
          {dataArr.map(d => (
            <Select.Option key={d.value}>{d.text}</Select.Option>
          ))}
        </Select>
      </React.Fragment>
    );
  }
}

export default Index;
