import React, { useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import SelectProvinceCombo from '@/componentWebs/Common/SelectProvinceCombo';
import { stringify } from 'qs';
import EncodeUrl from '@/utils/encode';

moment.locale('vi');
const { RangePicker } = DatePicker;
const Index = ({ defaultValue, International }) => {
  const [state, setState] = useState({
    travelTourPackageDepart: [moment(), moment().add(30, 'days')],
    diemDen: [],
    idDetail: "",
    diemDenText: ""
  });

  // const [travelTourPackageDepart, setTravelTourPackageDepart] = useState([mo]);

  const [isInternational, setIsInternational] = useState('0');
  const [typeSearch, setTypeSearch] = useState('1');
  const [textProvinces, setTextProvinces] = useState('');

  useEffect(() => {
    let textDiem = "";
    let diemDenValue = [];
    let departValue = ""

    if (defaultValue && defaultValue.diemDenText) {
      textDiem = defaultValue.diemDenText

    }

    if (defaultValue && defaultValue.travelTourDestinationLocations) {

      diemDenValue = defaultValue.travelTourDestinationLocations
    }
    if (defaultValue && defaultValue.travelTourPackageDepart) {

      departValue = defaultValue.travelTourPackageDepart
    }

    setState({
      ...state,
      diemDen: diemDenValue,
      diemDenText: textDiem,
      travelTourPackageDepart: departValue ? [moment(departValue[0]), moment(departValue[1])] : [moment(), moment().add(30, 'days')]

      // travelTourPackageDepart: [moment(departValue[0]), moment(departValue[1])]
    })

    if (defaultValue && defaultValue.isInternational) {
      setIsInternational(defaultValue.isInternational)
    }


  }, []);

  useEffect(
    () => {
      setIsInternational(International);
    },
    [International]
  );

  const onSubmit = e => {
    e.preventDefault();
    const {
      travelTourPackageDepart,
      diemDen,
      diemDenText,
    } = state;

    if (typeSearch === "1") {
      const params = {
        travelTourDestinationLocations: diemDen,
        isInternational,
        diemDenText,
        textProvinces,
        travelTourPackageDepart: [moment(travelTourPackageDepart[0], 'MM/DD/YYYY').format('YYYY-MM-DD'), moment(travelTourPackageDepart[1], 'MM/DD/YYYY').format('YYYY-MM-DD')],
      };

      window.location.href = `/searchCombo?${stringify(params)}`;
    } else {
      // if (!state.idDetai) return

      window.location.href = `/combo/${EncodeUrl(state.diemDenText)}/${state.idDetail}`;
    }


    // const params = {
    //   travelTourDestinationLocations: diemDen,
    //   isInternational,
    //   diemDenText,
    //   travelTourPackageDepart: [moment(travelTourPackageDepart[0], 'DD/MM/YYYY').format('YYYY-MM-DD'), moment(travelTourPackageDepart[1], 'DD/MM/YYYY').format('YYYY-MM-DD')],
    // };

    // window.location.href = `/searchCombo?${stringify(params)}`;
  };



  const handleChange = rec => {

    if (rec.typeSearch === "1") {
      setState({
        ...state,
        diemDen: [rec.countriesId, rec.provinceId],
        diemDenText: rec.text
      })
      setTypeSearch(rec.typeSearch)
      setTextProvinces(rec.provinceName)
    } else {
      setState({
        ...state,
        idDetail: rec.id,
        diemDenText: rec.text
      })
      setTypeSearch(rec.typeSearch)
    }

  }

  const clearValue = () => {
    setTypeSearch("1")
    setState({
      ...state,
      diemDen: [],
      idDetail: "",
      diemDenText: ""
    })

  }


  return (
    <form className="banner-main__box-form flex form-tour form-hotel-mobile ">
      <div className="banner-main__box-formItem searchKhachsanMobile">
        <SelectProvinceCombo
          label="Nhập địa điểm mà bạn muốn đến"
          placeholder="Quốc gia, thành phố, địa điểm du lịch"
          key="from"
          defaultValue={state.diemDenText}
          isInternational={isInternational}
          onChange={handleChange}
          clearValue={clearValue}

        // defaultValue={state.startName && `${state.startName}, ${state.startCountry}`}
        // onChange={rec =>
        //   setState({
        //     ...state,
        //     diemDen: [rec.countries && rec.countries.id, rec.id],
        //     diemDenText: `${rec.provinceName}, ${rec.countries && rec.countries.countrieName}`
        //   })
        // }
        />
      </div>
      <div className="banner-main__box-formItem searchKhachsanMobileDate" style={{ borderRight: 'none' }}>
        <div className="banner-main__input banner-main__select">
          <span className="label">Ngày khởi hành</span>

          <RangePicker
            placeholder={['Ngày kết thúc', 'Ngày bắt đầu']}
            // inputReadOnly
            format={['DD/MM/YYYY', 'DD/MM/YYYY']}
            disabledDate={val => val < moment().subtract(1, 'days')}
            allowClear={false}
            className='dateFiler'
            style={{ textAlign: 'left' }}
            onChange={value =>
              setState({
                ...state,
                travelTourPackageDepart: value,
              })
            }
            value={state.travelTourPackageDepart}
          />
        </div>

        <button className="btn btn--filter flex-a-c hidden-m-t" onClick={onSubmit} type="button">
          <span>Tìm kiếm</span>
          <img src="/static/vietnamTickets/images/Icon feather-search.svg" alt="search" className='lazyload' />
        </button>
      </div>

      {/* <div className="banner-main__box-formItem banner-main__box-formItem-search flex-b-c">

        <button className="btn btn--filter flex-a-c hidden-m-t" onClick={onSubmit} type="button">
          <span>Tìm kiếm</span>
          <img src="/static/vietnamTickets/images/Icon feather-search.svg" alt="search" />
        </button>
      </div> */}

      <button className="btn btn--filter flex-a-c hidden-pc" onClick={onSubmit} type="button">
        <span>Tìm kiếm</span>
        <img src="/static/vietnamTickets/images/Icon feather-search.svg" alt="search" className='lazyload' />
      </button>
    </form>
  );
};

export default Index;
