import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Drawer } from 'antd';
import styles from './index.less';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      type: props.type,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { visibleDrawer, type } = this.props;
    const { visible } = this.state;
    if (nextProps.visibleDrawer !== visibleDrawer) {
      this.setState({ visible: !visible });
    }
    if (nextProps.type !== type) {
      this.setState({ type: nextProps.type });
    }
  }

  onClose = () => {
    this.setState({ visible: false });
  };

  render() {
    const { children } = this.props;
    const { visible, type } = this.state;

    return (
      <div className="wrapper-drawer hidden-pc">
        <Drawer
          key={type}
          visible={visible}
          closable={false}
          className={type !== '1' && styles.sortFilter}
          width={type === '1' && '100%'}
          placement={type === '1' ? 'left' : 'bottom'}
          onClose={this.onClose}
          bodyStyle={{ padding: '18px' }}
        >
          {children}
        </Drawer>
      </div>
    );
  }
}

export default Index;
