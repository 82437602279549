import React, { useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import SelectProvince from '@/componentWebs/Common/SelectProvince';
import { stringify } from 'qs';
import SelectProvinceHotel from '@/componentWebs/Common/SelectProvinceHotel';
import EncodeUrl from '@/utils/encode';
import cookie from 'js-cookie'

moment.locale('vi');
const { RangePicker } = DatePicker;
const Index = ({ defaultValue, International }) => {
  const [state, setState] = useState({
    // travelTourPackageDepart: [moment(), moment()],
    diaDiem: [],
  });

  const [dateStart, setDateDen] = useState(moment());
  const [dateEnd, setDateDi] = useState(moment());
  const [diemDenText, setDiemDenText] = useState('');
  const [textProvinces, setTextProvinces] = useState('');
  const [idDetail, setidDetail] = useState('');
  const [typeSearch, setTypeSearch] = useState('1');

  useEffect(() => {
    let textDiem = '';
    let diemDenValue = [];
    let departValue = [];

    if (defaultValue && defaultValue.diemDenText) {
      setDiemDenText(defaultValue.diemDenText);
    }

    if (defaultValue && defaultValue.isInternational) {
      setIsInternational(defaultValue.isInternational);
    }

    if (defaultValue && defaultValue.diaDiem) {
      setState({
        ...state,
        diaDiem: defaultValue.diaDiem,
      });
    }
  }, []);

  const [isInternational, setIsInternational] = useState('0');

  useEffect(
    () => {
      setIsInternational(International);
    },
    [International]
  );

  const onSubmit = e => {
    e.preventDefault();
    const { travelTourPackageDepart, diaDiem } = state;
    // console.log("diaDiem: ", diaDiem);

    // if (!diaDiem || (diaDiem && diaDiem.length === 0)) return;

    if (typeSearch === '1') {
      const params = {
        diaDiem,
        isInternational,
        diemDenText,
        textProvinces,
        // travelTourPackageDepart: [moment(travelTourPackageDepart[0], 'DD/MM/YYYY').format('YYYY-MM-DD'), moment(travelTourPackageDepart[1], 'DD/MM/YYYY').format('YYYY-MM-DD')],
      };

      window.location.href = `/searchHotel?${stringify(params)}`;
    } else {
      window.location.href = `/danhsach-khach-san/${EncodeUrl(diemDenText)}/${idDetail}`;
    }
  };

  const handleChange = rec => {

    if (rec.typeSearch === "1") {
      setDiemDenText(rec.text)
      setState({
        ...state,
        diaDiem: [rec.countriesId, rec.provinceId],
      })
      setTypeSearch(rec.typeSearch)
      setTextProvinces(rec.provinceName)
    } else {
      setDiemDenText(rec.text)
      setidDetail(rec.id)
      setTypeSearch(rec.typeSearch)
    }

  }

  const disabledDate = (current) => {
    // Can not select days before today and today
    const data = (cookie.get('dateStart') && moment(cookie.get('dateStart')) || dateStart || moment())
    const clone = _.cloneDeep(data);
    return current && current < clone;
  }

  const disableReceive = (current) => {
    // Can not select days before today and today
    const data = moment()
    const clone = _.cloneDeep(data);
    return current && current < clone.subtract(1, 'days');
  }

  const clearValue = () => {
    setTypeSearch("1")
    setDiemDenText("")
    setidDetail("")
    setState({
      ...state,
      diaDiem: [],
    })
    setTextProvinces("")

  }

  return (
    <form className="banner-main__box-form flex form-hotel1  form-hotel-mobile">
      {/* form-hotel */}
      <div className="banner-main__box-formItem searchKhachsanMobile">
        <SelectProvinceHotel
          label="Địa điểm"
          placeholder="Quốc gia, thành phố, địa điểm du lịch"
          key="from"
          defaultValue={diemDenText}
          isInternational={isInternational}
          onChange={handleChange}
          clearValue={clearValue}

        // onChange={rec => {
        //   setState({
        //     ...state,
        //     diaDiem: [rec.countries && rec.countries.id, rec.id],
        //     // diemDenText: `${rec.provinceName}, ${rec.countries && rec.countries.countrieName}`
        //   });
        //   setDiemDenText(`${rec.provinceName}, ${rec.countries && rec.countries.countrieName}`);
        // }}
        />


      </div>
      <div className='banner-main__box-formItem searchKhachsanMobileDate'>
        <div className="inputDateSearch" >
          <div className="banner-main__input banner-main__select">
            <span className="label">Ngày nhận phòng</span>

            <DatePicker
              placeholder="Ngày nhận phòng"
              // inputReadOnly
              format="DD/MM/YYYY"
              allowClear={false}
              style={{ textAlign: 'left' }}
              value={cookie.get('dateStart') && moment(cookie.get('dateStart')) || dateStart}
              className='dateFiler'
              disabledDate={disableReceive}
              onChange={value => {
                setDateDen(value)
                cookie.set('dateStart', value)
                if (value - (cookie.get('dateEnd') && moment(cookie.get('dateEnd')) || dateEnd) > 0) {
                  setDateDi(value)
                  cookie.set('dateEnd', value)
                }
              }}
            // value={state.travelTourPackageDepart}
            />
          </div>
        </div>
        <div className="inputDateSearch">
          <div className="banner-main__input banner-main__select">
            <span className="label">Ngày trả phòng</span>

            <DatePicker
              placeholder="Ngày trả phòng"
              // inputReadOnly
              format="DD/MM/YYYY"
              allowClear={false}
              disabledDate={disabledDate}
              style={{ textAlign: 'left' }}
              value={cookie.get('dateEnd') && moment(cookie.get('dateEnd')) || dateEnd}
              className='dateFiler'
              onChange={value => {
                setDateDi(value)
                cookie.set('dateEnd', value)
              }}
            />
          </div>
        </div>
      </div>
      <div className='banner-main__box-formItem searchKhachsanMobileButton'>
        <button className="btn btn--filter flex-a-c" onClick={onSubmit} type="button">
          <span>Tìm kiếm</span>
          <img src="/static/vietnamTickets/images/Icon feather-search.svg" alt="search" className='lazyload' />
        </button>
      </div>
      {/* <button className="btn btn--filter flex-a-c hidden-pc" onClick={onSubmit} type="button">
        <span>Tìm kiếm</span>
        <img src="/static/vietnamTickets/images/Icon feather-search.svg" alt="search" />
      </button> */}
    </form>
  );
};

export default Index;
