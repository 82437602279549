import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { getResponsiveImage } from '@/componentWebs/NbmImageNew';

let timer = null;
const pageSize = 100
const Index = ({
  dispatch,
  label,
  onChange,
  placeholder,
  refCallback,
  isInternational,
  defaultValue,
  clearValue
}) => {
  const input = refCallback || useRef();
  const [dataList, setDataList] = useState([]);
  const [dataListNotValue, setDataListNotValue] = useState([]);

  const [typeSearch, setTypeSearch] = useState('1');
  const [value, setValue] = useState('');
  const [isFocus, setIsfocus] = useState(false);

  // useEffect(
  //   () => {

  //   },
  //   [defaultValue]
  // );

  useEffect(
    () => {
      setValue(defaultValue)
    }, [defaultValue]
  )


  useEffect(
    () => {
      dispatch({
        type: 'webs/fetchAutoComplete',
        payload: {
          filter: {
            typesId: '5',
            isInternational
          },
          // sort: ['countrieName', 'asc'],
          range: [0, pageSize - 1],
          // attributes: `id,countrieName`,
        },
        // payload: {
        //   filter: JSON.stringify({ isInternational }),
        //   sort: JSON.stringify(['provinceName', 'asc']),
        //   range: JSON.stringify([0, 20]),
        //   attributes: `id,provinceName,provinceIdentificationCode`,

        // },
        callback: res => {
          if (res.success) {
            // eslint-disable-next-line no-underscore-dangle
            const _list = (res.result && res.result.list) || [];
            setDataList(_list);
            setDataListNotValue(_list);
          }
        },
      });
    },
    [isInternational]
  );

  const onChangeText = e => {
    // eslint-disable-next-line no-underscore-dangle
    const _value = e.target.value;
    setValue(_value);
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      if (!_value || (_value && !_value.trim())) {
        setDataList(dataListNotValue);
        setTypeSearch('1');
      } else {
        const params = {
          isInternational,
          typesId: '5',
          name: _value && _value.trim(),
        };
        // if (!_value) {
        //   delete params.provinceName;
        // }
        dispatch({
          type: 'webs/fetchAutoCompleteSearch',
          payload: {
            filter: params,
            // sort: ['countrieName', 'asc'],
            range: [0, pageSize - 1],
            // attributes: `id,countrieName`,
          },
          // payload: {
          //   filter: JSON.stringify(params),
          //   sort: JSON.stringify(['provinceName', 'asc']),
          //   range: JSON.stringify([0, 99]),
          //   attributes: `id,provinceName,provinceIdentificationCode`,
          // },
          callback: res => {
            if (res.success) {
              // eslint-disable-next-line no-underscore-dangle
              const _list = (res.result && res.result.list) || [];
              setDataList(_list);
              setTypeSearch('2');
            }
          },
        });
      }
    }, 200);
  };
  // useEffect(() => {
  //   if (onFocus) {
  //     input.current.focus()
  //   }
  // }, [onFocus])

  const onClickLi = (item) => {
    let newItem = {
    };

    if (typeSearch === "1") {
      setValue(`${item.provinceName}, ${item.countries && item.countries.countrieName}`);
      newItem = {
        //  id: item.id,
        countriesId: item.countries && item.countries.id || "",
        provinceId: item.id,
        text: `${item.provinceName}, ${item.countries && item.countries.countrieName}`,
        typeSearch,
        provinceName: item.provinceName
      }
    } else {
      setValue(`${item.travelTourName || ""}`);

      newItem = {
        // countriesId: item.countries && item.countries.id || "",
        // provinceId: item.id,
        // text: `${item.provinceName}, ${item.countries && item.countries.countrieName}`,
        text: item.travelTourName,
        typeSearch,
        id: item.id
      }
    }

    if (onChange) {
      onChange(newItem);
    }

  }

  const handleClear = () => {
    setValue("")
    setTypeSearch('1');
    setDataList(dataListNotValue);
    if (clearValue) {
      clearValue();
    }
  }



  return (
    <div className="banner-main__input banner-tour banner-clear banner-hotel-mobile-clear">
      <span className="label">{label}</span>
      <input
        ref={input}
        type="text"
        placeholder={placeholder}
        value={value}
        onFocus={() => setIsfocus(true)}
        onChange={onChangeText}
        onBlur={() => {
          setTimeout(() => { setIsfocus(false) }, 200)
        }}
      />
      {value && <i className="far fa-times-circle" onClick={handleClear} />}

      <div
        // className="select-menu " 
        className={typeSearch === '1' ? 'select-menu select-menu-type1' : 'select-menu select-menu-type2'}
        style={{ display: isFocus ? 'block' : 'none' }}>
        {/* <h5 className="select-menu__title">Địa điểm</h5> */}

        <ul className="select-menu__list select-autocomplete">
          {dataList &&
            dataList.length > 0 &&
            dataList.map(item => (
              <li
                className="flex-a-c"
                key={item.id}
                onClick={() => onClickLi(item)}
              // onClick={() => {
              //   setValue(
              //     `${item.provinceName}, ${item.countries && item.countries.countrieName}`
              //   );
              //   if (onChange) {
              //     onChange(item);
              //   }
              // }}
              >
                {/* <b>{item.provinceIdentificationCode}</b> */}
                {typeSearch === '1' && (
                  <img
                    src={`${(item &&
                      item.images &&
                      item.images[0] &&
                      item.images[0].file &&
                      getResponsiveImage(item.images[0].file)) ||
                      '/static/vietnamTickets/images/unimage.png'}`}
                    className="img_des lazyload"
                    style={{ marginRight: '25px' }}
                  />
                )}

                {typeSearch === '2' && (
                  <img
                    src={`${(item &&
                      item &&
                      item.travelTourImage &&
                      item.travelTourImage[0] &&
                      item.travelTourImage[0].file &&
                      getResponsiveImage(item.travelTourImage[0].file)) ||
                      '/static/vietnamTickets/images/unimage.png'}`}
                    className="img_des lazyload"
                    style={{ marginRight: '25px' }}
                  />
                )}

                {/* Tim kiem dia diem ban dau  */}
                {typeSearch === '1' && (
                  <div className="box-info">
                    <span style={{ display: 'inline-block', marginRight: '5px' }}>
                      {item.provinceName}
                    </span>
                    <span>{`(${item.totalTours} combo)`} </span>
                  </div>
                )}

                {/* Khi co value go vao  */}
                {typeSearch === '2' && (
                  <div className="box-info">
                    <span style={{ display: 'inline-block', marginRight: '5px' }}>
                      {item.travelTourName}
                    </span>
                    {/* <span>{`(${item.countries && item.countries.countrieName})`} </span> */}
                  </div>
                )}

                {/* <span>
                  {item.provinceName}, {item.countries && item.countries.countrieName}
                </span> */}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default connect()(Index);
