/**
 * đối tượng routes cho web và cms
 * @param { là tên không in hoa = tiếng anh viết liền, cách nhau bằng dấu _ và là duy nhất } name
 * @param { là đường dẫn trên trình duyệt, bắt đầu bằng / } path
 * @param { là đường dẫn đến file vật lý trong thư mục pages } component
*/
module.exports.routes = {
  // web
  web: [
    { path: '/', name: "home", component: 'index' },
    { path: '/home', name: "home", component: 'index' },
    {
      path: '/searchTicket', name: 'searchTicket', component: 'User/SearchTicket'
    },
    {
      path: '/searchTour', name: 'searchTour', component: 'User/SearchTour'
    },
    {
      path: '/searchCombo', name: 'searchCombo', component: 'User/SearchCombo'
    },
    {
      path: '/searchVisa', name: 'searchVisa', component: 'User/SearchVisa'
    },
    {
      path: '/searchHotel', name: 'searchHotel', component: 'User/SearchHotel'
    },
    {
      path: '/checkout', name: 'checkout', component: 'User/CheckoutPage'
    },
    {
      path: '/tim-kiem-dia-diem/:parentName([a-zA-Z0-9-]{1,100})/:id([0-9]{1,100})',
      name: 'SearchDiaDiem',
      component: 'User/SearchDiaDiem',
    },
    {
      path: '/chinh-sach/:parentName([a-zA-Z0-9-]{1,100})',
      name: 'ChinhSach',
      component: 'User/ChinhSach',
    },
    {
      path: '/tour/:parentName([a-zA-Z0-9-]{1,100})/:id([0-9]{1,100})',
      name: 'DetailTour',
      component: 'User/DetailTour',
    },

    {
      path: '/danhsach-khach-san/:parentName([a-zA-Z0-9-]{1,100})/:id([0-9]{1,100})',
      name: 'DetailHotel',
      component: 'User/DetailHotel',
    },
    {
      path: '/combo/:parentName([a-zA-Z0-9-]{1,100})/:id([0-9]{1,100})',
      name: 'DetailCombo',
      component: 'User/DetailCombo',
    },
    // { path: '/gioi-thieu-:id(\\d+)', name: "about", component: 'User/about' },
    { path: '/:name([a-zA-Z0-9\-]{1,100})', name: "category", component: 'User/Category' },
    {
      path: '/:parentName([a-zA-Z0-9-]{1,100})/:title([a-zA-Z0-9\-]{1,100})/:id([0-9]{1,100})',
      name: 'DetailTuyenBay',
      component: 'User/DetailTuyenBay',
    },
  ],
  dashboard: []
};


