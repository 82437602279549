import React from 'react';

const defaultProps = {
  initialPage: 1
}

class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pager: {} };
  }

  componentDidMount() {
    const { total, initialPage } = this.props
    if (total && total > 0) {
      this.setPage(initialPage);
    }
  }

  componentDidUpdate(prevProps) {
    const { total, initialPage } = this.props
    // reset page if items array has changed
    if (total !== prevProps.total) {
      this.setPage(initialPage);
    }
  }

  setPage = (page) => {
    const { total, pageSize } = this.props
    const { pager } = this.state
    let pagers = pager;
    if (page < 1 || page > pager.totalPages) {
      return;
    }
    pagers = this.getPager(total, page, pageSize);
    this.setState({ pager: pagers });
  }

  getPager = (totalItems, currentPage, pageSize) => {
    const currentPages = currentPage || 1;
    const pageSizes = pageSize || 10;
    const totalPages = Math.ceil(totalItems / pageSizes);

    let startPage; let endPage;
    if (currentPages <= 1)
      startPage = 1;
    else
      startPage = (currentPages - 1);


    if ((totalPages - (startPage + 2)) >= 0) {
      endPage = startPage + 2;
    } else {
      endPage = totalPages;
    }

    // calculate start and end item indexes
    const startIndex = (currentPages - 1) * pageSizes;
    const endIndex = Math.min(startIndex + pageSizes - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems,
      currentPage: currentPages,
      pageSize: pageSizes,
      totalPages,
      startPage,
      endPage,
      startIndex,
      endIndex,
      pages
    };
  }

  render() {
    const { pager } = this.state
    const { onChange } = this.props
    if (!pager.pages || pager.pages.length <= 1) {
      return null;
    }
    return (
      <React.Fragment>
        <div className='pagination-ticket'>
          <a
            className={`pagination-ticket-nav ${pager.currentPage !== 1 && 'active-pagination'}`}
            onClick={() => { if (pager.currentPage !== 1) { this.setPage(pager.currentPage - 1); onChange(pager.currentPage - 1) } }}
          >
            <i className="fa fa-angle-left" aria-hidden="true" />
          </a>
          <div className='pagination-content'>
            {pager.pages.map((page) =>
              <React.Fragment key={page}>
                {pager.currentPage === page &&
                  <a
                    className='pagination-number active-pagination'
                  >
                    {page}
                  </a>
                }
                {pager.currentPage !== page &&
                  <a
                    className='pagination-number'
                    onClick={() => { this.setPage(page); onChange(page) }}
                  >
                    {page}
                  </a>
                }
              </React.Fragment>
            )}
          </div>
          <a
            className={`pagination-ticket-nav ${pager.currentPage !== pager.totalPages && 'active-pagination'}`}
            onClick={() => { if (pager.currentPage !== pager.totalPages) { this.setPage(pager.currentPage + 1); onChange(pager.currentPage + 1) } }}
          >
            <i className="fa fa-angle-right" aria-hidden="true" />
          </a>
        </div>
        <div className="clr" />
      </React.Fragment >
    );
  }
}

Pagination.defaultProps = defaultProps;

export default Pagination
