import React, { Component } from 'react';
// import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Cascader } from 'antd';
import { /* defineMessages, */ injectIntl } from 'react-intl';
import { connect } from 'react-redux';

@connect(({ countries }) => ({
  countries,
}))
// @Form.create()
@injectIntl
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || '',
      data: []
    };
  }

  componentDidMount() {
    this.fetch()
  }

  fetch = () => {
    const { dispatch, value } = this.props;
    const params = {
      filter: { status: 1 },
      range: [0, 999],
      sort: ['countrieName', 'ASC'],
      attributes: 'id,countrieName'
    };
    dispatch({
      type: 'webs/fetchLazyLoading',
      payload: params,
      callback: result => {
        console.log("ressss: ", result);
        if (result && result.success === true) {
          // log('result group', result)
          const data =
            result &&
            result.result &&
            result.result.list.map(user => ({
              label: user.countrieName,
              value: user.id,
              isLeaf: false
            }))
          // console.log('data: ', data)
          this.setState({
            data
          }, () => {
            if (value.length > 1) {
              const dataInfo = data.filter((item) => item.value === value[0])
              if (dataInfo.length > 0) {
                this.loadData(dataInfo)
              }
            }
          })
        }
      },
    });
  };

  loadData = selectedOptions => {
    const { data } = this.state;
    const { dispatch } = this.props;
    const targetOption = selectedOptions[selectedOptions.length - 1];
    // targetOption.loading = true;
    const params = {
      filter: JSON.stringify({ status: 1, countriesId: targetOption.value }),
      range: JSON.stringify([0, 999]),
      sort: JSON.stringify(['provinceName', 'ASC']),
      attributes: 'id,provinceName'
    };
    dispatch({
      type: 'webs/fetchProvincesSelect',
      payload: params,
      callback: result => {
        // targetOption.loading = false;
        if (result && result.success === true) {
          // log('result group', result)
          const data2 =
            result &&
            result.result &&
            result.result.list.map(user => ({
              label: user.provinceName,
              value: user.id,
            }));
          targetOption.children = data2
          const dataNew = data.filter(obj => targetOption.value !== obj.value || targetOption.label !== obj.label)
          this.setState({ data: [...dataNew, targetOption].sort((a, b) => (a.label).localeCompare(b.label)) })
        }
      },
    });
  }

  onChange = (value) => {
    const { onChange, isModal } = this.props;

    if (isModal) {
      if (value.length > 1 || value.length === 0) {
        this.setState({ value })
        if (onChange) onChange(value)
      }
    } else {
      if (onChange) onChange(value)
      this.setState({ value })
    }
  }

  render() {
    const { allowClear, placeholder, disabled, size } = this.props;
    const { data, value } = this.state;
    // console.log('value: ', value)
    return (
      <Cascader
        options={data}
        allowClear={allowClear}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        size={size}
        loadData={this.loadData}
        onChange={this.onChange}
        changeOnSelect
        style={{width: '100%'}}
      />
    );
  }
}

export default Index;
