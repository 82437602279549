import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { debounce } from 'lodash'

let timer = null
const Index = ({ dispatch, label, onChange, placeholder, refCallback, isInternational, defaultValue }) => {
  const input = refCallback || useRef()
  const [dataList, setDataList] = useState([])
  const [value, setValue] = useState("")
  const [isFocus, setIsfocus] = useState(false)

  useEffect(() => {
    dispatch({
      type: 'webs/fetchProvincesSelect',
      payload: {
        filter: JSON.stringify({ isInternational }),
        sort: JSON.stringify(['provinceName', 'asc']),
        range: JSON.stringify([0, 20]),
        attributes: `id,provinceName,provinceIdentificationCode`,

      },
      callback: res => {
        if (res.success) {
          // eslint-disable-next-line no-underscore-dangle
          const _list = res.result && res.result.list || []
          setDataList(_list)
        }
      },
    });
  }, [isInternational])

  const onChangeText = (e) => {
    // eslint-disable-next-line no-underscore-dangle
    const _value = e.target.value
    setValue(_value)
    if (timer) {
      clearTimeout(timer)
      timer = null
    }
    debounce(() => {
      const params = {
        isInternational,
        provinceName: _value && _value.trim()
      }
      if (!_value) {
        delete params.provinceName
      }
      dispatch({
        type: 'webs/fetchProvincesFlights',
        payload: {
          filter: JSON.stringify(params),
          sort: JSON.stringify(['provinceName', 'asc']),
          range: JSON.stringify([0, 99]),
          attributes: `id,provinceName,provinceIdentificationCode`,

        },
        callback: res => {
          if (res.success) {
            // eslint-disable-next-line no-underscore-dangle
            const _list = res.result && res.result.list || []
            setDataList(_list)
          }
        },
      });
    }, 500)
  }
  // useEffect(() => {
  //   if (onFocus) {
  //     input.current.focus()
  //   }
  // }, [onFocus])

  return (
    <div className="banner-main__input">
      <span className="label">{label}</span>
      <input
        ref={input}
        type="text"
        placeholder={defaultValue || placeholder}
        value={value}
        onFocus={() => setIsfocus(true)}
        onChange={onChangeText}
        onBlur={() => {
          setTimeout(() => { setIsfocus(false) }, 200)
        }}
      />
      <div className="select-menu " style={{ display: isFocus ? 'block' : 'none' }}>
        <h5 className="select-menu__title">
          Địa điểm
        </h5>
        <ul className="select-menu__list">
          {dataList && dataList.length > 0 && dataList.map(item => (
            <li
              key={item.id}
              onClick={() => {
                setValue(`${item.provinceName}, ${item.countries && item.countries.countrieName}`)
                if (onChange) {
                  onChange(item)
                }
              }}
            >
              <b>{item.provinceIdentificationCode}</b>
              <span>{item.provinceName}, {item.countries && item.countries.countrieName}</span>
            </li>

          ))}
        </ul>
      </div>
    </div>
  )
}

export default connect()(Index)