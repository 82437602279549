/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-danger */
/* eslint-disable global-require */
import React from 'react';
import cookie from 'cookie';
import dynamic from 'next/dynamic';
import getConfig from 'next/config';
import { getComponentDisplayName } from '../utils/helpers';

const modifyCategoryWithTemplate = (category, checker) => {
  const categoriesTemplateLayout = (category && category.categoriesTemplateLayout) || {};
  const pageTemplate =
    categoriesTemplateLayout &&
    categoriesTemplateLayout.length > 0 &&
    categoriesTemplateLayout.find(e => e && e.isHome === checker);
  const modifedCategory = {
    ...category,
    templateLayouts: pageTemplate && pageTemplate.templateLayouts,
  };

  return modifedCategory;
};
const { publicRuntimeConfig } = getConfig();
const Control = dynamic(() => import('./ControlLayout'), {
  ssr: true,
  loading: () => null,
});

export default ComposedComponent => {
  class WebLayout extends React.Component {
    static displayName = `WebLayout(${getComponentDisplayName(ComposedComponent)})`;

    static async getInitialProps(context) {
      const { req, asPath, pathname, query, store, dispatch } = context;
      let cookies;
      // let protocol = 'https:';
      if (req) {
        cookies = req.cookies;
      } else {
        const documentCookie = document.cookie;
        cookies = cookie.parse(documentCookie);
      }
      const querySite = {
        filter: {
          status: "1",
          url: `mybooking.com.vn`,
          languagesId: "1"
        },
        attributes: `id,url,name,seoKeywords,seoDescriptions,logo,icon`
      };

      await dispatch({
        type: 'webs/fetchSiteUrl',
        payload: querySite,

      });

      const {
        webs: { dataSite },
      } = await store.getState();
      const SITEID_WEB = await dataSite && dataSite.id || publicRuntimeConfig.SITEID_WEB;
      const queryCategory = await {
        filter: {
          status: "1",
          menuPositionsId: `${publicRuntimeConfig.MENU__POSITIONID_TOP},${publicRuntimeConfig.MENU__POSITIONID_BOTTOM},${publicRuntimeConfig.MENU__POSITIONID_LEFT}`,
          languagesId: "1",
          sitesId: SITEID_WEB,
        },
        sort: ['orderBy', 'ASC'],
      };
      const queryAd = await {
        filter: {
          status: "1",
          sitesId: SITEID_WEB,
          languagesId: "1",
          adsTypeId: publicRuntimeConfig.SLIDEID,
        },
      };
      const queryCategoryHome = await {
        filter: {
          status: '1',
          isHome: true,
          languagesId: '1',
          sitesId: SITEID_WEB,
        },
        sort: ['orderBy', 'ASC'],
      };
      if (SITEID_WEB) {
        await dispatch({
          type: 'webs/fetchMenus',
          payload: queryCategory,
        });
        await dispatch({
          type: 'webs/fetchAllAd',
          payload: queryAd,
        });
        if (pathname === '/' || pathname === '/index') {
          await dispatch({
            type: 'webs/fetchAllCategory',
            payload: queryCategoryHome,
          });
        }
      }
      const {
        webs: { dataAd, dataCategoryAll, data },
      } = await store.getState();
      const menus = data?.list || [];
      // console.log('menu',menus)
      const menuHeader =
        (menus && menus.length > 0 && menus.filter(item => Number(item.menuPositionsId) === Number(publicRuntimeConfig.MENU__POSITIONID_TOP))) ||
        [];
      const menuHeaderFilter =
        (menuHeader && menuHeader.length > 0 && menuHeader.filter(item => !item.parent)) || [];
      const menuFooter =
        (menus && menus.length > 0 && menus.filter(item => Number(item.menuPositionsId) === Number(publicRuntimeConfig.MENU__POSITIONID_BOTTOM))) ||
        [];
      const menuFooterFilter =
        (menuFooter && menuFooter.length > 0 && menuFooter.filter(item => !item.parent)) || [];

      const menuLeft =
        (menus && menus.length > 0 && menus.filter(item => Number(item.menuPositionsId) === Number(publicRuntimeConfig.MENU__POSITIONID_LEFT))) ||
        [];
      const menuLeftFilter =
        (menuLeft && menuLeft.length > 0 && menuLeft.filter(item => !item.parent)) || [];
      const newSiteProfile = dataSite && dataSite.siteProfiles && dataSite.siteProfiles.length > 0
        && dataSite.siteProfiles.find(x => x.languagesId === '1')
      const modifiedCategoryAll = (dataCategoryAll?.result?.list?.map(item => modifyCategoryWithTemplate(item, true))) || [];
      const sortData = modifiedCategoryAll?.length > 0 && modifiedCategoryAll.sort((a, b) => a.orderHome - b.orderHome);
      // console.log('dataCategoryAll', dataCategoryAll);
      let ogImage = dataAd?.length > 0 && dataAd[0].contents;
      ogImage = `${publicRuntimeConfig.IMAGE_SERVER_NEW}/${publicRuntimeConfig.IMAGE_PROJECT}${ogImage}`;
      const newDataSite = {
        ...dataSite,
        siteProfiles: newSiteProfile || {}
      }
      return {
        ...(ComposedComponent.getInitialProps
          ? await ComposedComponent.getInitialProps(context)
          : {}),
        modifiedCategoryAll: sortData || [],
        cookies,
        asPath,
        pathname,
        dataSite: newDataSite,
        query,
        menuLeft: menuLeftFilter,
        menuHeader: menuHeaderFilter,
        menuFooter: menuFooterFilter,
        dataSlide: dataAd,
        ogImage
      };
    }

    render() {
      // const { modifiedCategoryAll, dataSite } = this.props;
      // console.log(modifiedCategoryAll);
      return (
        <React.Fragment>
          <Control {...this.props}>
            <ComposedComponent
              {...this.props}
            />
          </Control>
        </React.Fragment>
      );
    }
  }

  return WebLayout;
};
