import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { getResponsiveImage } from '@/componentWebs/NbmImageNew';

let timer = null;
const Index = ({ dispatch, label, onChange, clearValue, placeholder, refCallback, defaultValue }) => {
  const input = refCallback || useRef();
  const [dataList, setDataList] = useState([]);
  const [dataListNotValue, setDataListNotValue] = useState([]);

  const [value, setValue] = useState('');
  const [typeSearch, setTypeSearch] = useState('1');
  const [isFocus, setIsfocus] = useState(false);

  useEffect(() => {
    dispatch({
      type: 'webs/fetchAutoComplete',
      payload: {
        filter: {
          typesId: '3',
        },
        // sort: ['countrieName', 'asc'],
        range: [0, 24 - 1],
        // attributes: `id,countrieName`,
      },
      callback: res => {
        if (res.success) {
          // eslint-disable-next-line no-underscore-dangle
          const _list = (res.result && res.result.list) || [];
          setDataList(_list);
          setDataListNotValue(_list);
        }
      },
    });
  }, []);

  useEffect(
    () => {
      setValue(defaultValue)
    }, [defaultValue]
  )

  const handleClear = () => {
    setValue("")
    setTypeSearch("1")
    setDataList(dataListNotValue);
    if (clearValue) {
      clearValue();
    }
  }

  const onChangeText = e => {
    // eslint-disable-next-line no-underscore-dangle
    const _value = e.target.value;

    setValue(_value);
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      if (!_value || (_value && !_value.trim())) {
        setDataList(dataListNotValue);
        setTypeSearch('1');
      } else {
        const params = {
          typesId: '3',
          name: _value && _value.trim(),
        };
        // if (!_value) {
        //   delete params.countrieName;
        // }
        dispatch({
          type: 'webs/fetchAutoCompleteSearch',
          payload: {
            filter: params,
            // sort: ['countrieName', 'asc'],
            range: [0, 24 - 1],
            // attributes: `id,countrieName`,
          },
          callback: res => {
            if (res.success) {
              // eslint-disable-next-line no-underscore-dangle
              const _list = (res.result && res.result.list) || [];
              setDataList(_list);
              setTypeSearch('2');
            }
          },
        });
      }
    }, 200);
  };

  const onClickLi = (item) => {
    let newItem = {
    };

    if (typeSearch === "1") {
      setValue(`${item.countrieName}`);
      newItem = {
        id: item.id,
        countrieName: item.countrieName
      }
    } else {
      setValue(`${item.travelVisaName || ""}`);

      newItem = {
        id: item.countries && item.countries.id || '',
        countrieName: item.travelVisaName || '',
      }
    }

    if (onChange) {
      onChange(newItem);
    }

  }

  // useEffect(() => {
  //   if (onFocus) {
  //     input.current.focus()
  //   }
  // }, [onFocus])

  return (
    <div className="banner-main__input banner-clear  banner-clear-visa">
      <span className="label">{label}</span>
      <input
        ref={input}
        type="text"
        placeholder={placeholder}
        value={value}
        onFocus={() => setIsfocus(true)}
        onChange={onChangeText}
        onBlur={() => {
          setTimeout(() => {
            setIsfocus(false);
          }, 200);
        }}
      />
      {value && <i className="far fa-times-circle" onClick={handleClear} />}

      <div
        className={typeSearch === '1' ? 'select-menu select-menu-type1' : 'select-menu select-menu-type2'}
        style={{ display: isFocus ? 'block' : 'none' }}
      >
        {/* <h5 className="select-menu__title">Quốc gia</h5> */}
        {/* <div className="select-menu-list__wrapper">

        </div> */}
        <ul className="select-menu__list select-autocomplete">
          {dataList &&
            dataList.length > 0 &&
            dataList.map(item => (
              <li
                key={item.id}
                // onClick={() => {
                //   setValue(`${item.countrieName}`);
                //   if (onChange) {
                //     onChange(item);
                //   }
                // }}
                onClick={() => onClickLi(item)}
                className="flex-a-c"
              >
                {typeSearch === '1' && (
                  <img
                    src={`${(item &&
                      item.images &&
                      item.images[0] &&
                      item.images[0].file &&
                      getResponsiveImage(item.images[0].file)) ||
                      '/static/vietnamTickets/images/unimage.png'}`}
                    className="img_des lazyload"
                    style={{ marginRight: '25px' }}
                  />
                )}

                {typeSearch === '2' && (
                  <img
                    src={`${(item &&
                      item.countries &&
                      item.countries.images &&
                      item.countries.images[0] &&
                      item.countries.images[0].file &&
                      getResponsiveImage(item.countries.images[0].file)) ||
                      '/static/vietnamTickets/images/unimage.png'}`}
                    className="img_des lazyload"
                    style={{ marginRight: '25px' }}
                  />
                )}

                {/* Tim kiem dia diem ban dau  */}
                {typeSearch === '1' && (
                  <div className="box-info">
                    <span style={{ display: 'inline-block', marginRight: '5px' }}>
                      {item.countrieName}
                    </span>
                    <span>{`(${item.totalVisaPackages} visa)`} </span>
                  </div>
                )}

                {/* Khi co value go vao  */}
                {typeSearch === '2' && (
                  <div className="box-info">
                    <span style={{ display: 'inline-block', marginRight: '5px' }}>
                      {item.travelVisaName}
                    </span>
                    <span>{`(${item.countries && item.countries.countrieName})`} </span>
                  </div>
                )}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default connect()(Index);
